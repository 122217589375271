import { createUserApi } from 'api/users/usersApi'
import { agentUpdateUser } from 'api/agents'
import { failUserSignup, failUserUpdate, screenEmail } from 'api/auth'
import { Dexie } from 'dexie'
import toast from 'react-hot-toast'

const db = new Dexie('AgentSampling')
db.version(2).stores({
  agentSamplingOffline: '++id, userData'
})

export const USER_ACTIONS = { create: 'create', update: 'update' }

export const SyncData = async payload => {
  try {
    const recordsEmail = await db.agentSamplingOffline.toArray()
    if (recordsEmail.length > 0) {
      toast.success('Syncing Data in progress..')
      for (const recordEmail of recordsEmail) {
        // do bright verify check
        const responseEmail = await screenEmail(recordEmail.userData.user.email)
        if (responseEmail.error === 'Email account invalid') {
          toast.error(`error : Please use a valid email address user : ${recordEmail.userData.user.email}`)
          await db.agentSamplingOffline.delete(recordEmail.id)
        } else if (
          responseEmail.status === 'unknown' ||
          responseEmail.error === 'Email domain invalid' ||
          responseEmail.error === 'Email address invalid'
        ) {
          if (responseEmail.error === 'Email address invalid') {
            toast.error(
              `error : Email address is not valid please use valid email address user : ${recordEmail.userData.user.email}`
            )
          } else {
            toast.error(
              `error : Email domain is not valid please use valid email address user : ${recordEmail.userData.user.email}`
            )
          }
          await db.agentSamplingOffline.delete(recordEmail.id)
        } else {
          // Success do nothing
        }
      }
      const countRecords = await db.agentSamplingOffline.count()
      if (countRecords === 0) {
        toast.success('Syncing Data completed')
        return { success: true }
      }
    }

    const records = await db.agentSamplingOffline.toArray()
    let promiseArray = []
    if (records.length > 0) {
      // toast.success('Syncing Data in progress..')

      for (const record of records) {
        promiseArray.push(
          await createUserApi(record.userData)
            .then(() => ({ success: true, record }))
            .catch(error => ({ success: false, record, error }))
        )
      }
      const results = await Promise.all(promiseArray)

      for (const result of results) {
        if (result.success) {
          toast.success(`${result.record.userData.user.email} created`)
          // Remove successfully saved user from the original table
          await db.agentSamplingOffline.delete(result.record.id)
        } else {
          toast.error(
            `error : ${result.error?.errorResponse?.errorMessage} user : ${result.record.userData.user.email}`
          )

          try {
            if (result.error?.errorResponse?.message !== 'emailAlreadyExists') {
              const payload = {
                userData: result.record.userData,
                error: {
                  status: result.error?.status,
                  message: result.error?.statusText || result.error?.message || result.error
                }
              }
              await failUserSignup(payload)
            }
          } catch (failUserError) {
            setTimeout(SyncData, 30000)
            console.log('failUserError', failUserError)
          }
          await db.agentSamplingOffline.delete(result.record.id)
        }
      }
      toast.success('Syncing Data completed')
      return { success: true }
    }
  } catch (error) {
    console.log(error)
  }
}

export const createOrUpdateUser = async payload => {
  const requestBody = {
    user: payload.user,
    agentJWT: payload.agentJWT,
    agentSource: payload.agentSource,
    storeName: 'vype'
  }

  // Create
  if (payload.action === USER_ACTIONS.create) {
    try {
      if (!navigator.onLine) {
        await db.agentSamplingOffline.add({ userData: requestBody })
        return { success: true, mode: 'offline' }
      } else {
        // doing Brite Verify check before submission
        const responseEmail = await screenEmail(requestBody.user.email)
        if (responseEmail.error === 'Email account invalid') {
          return {
            success: false,
            errorResponse: {
              message: 'Email account invalid',
              errorMessage: 'Please use a valid email address'
            }
          }
        } else if (
          responseEmail.status === 'unknown' ||
          responseEmail.error === 'Email domain invalid' ||
          responseEmail.error === 'Email address invalid'
        ) {
          if (responseEmail.error === 'Email address invalid') {
            return {
              success: false,
              errorResponse: {
                message: 'Email address invalid',
                errorMessage: 'Email address is not valid please use valid email address'
              }
            }
          } else {
            return {
              success: false,
              errorResponse: {
                message: 'Email domain invalid',
                errorMessage: 'Email domain is not valid please use valid email address'
              }
            }
          }
        } else {
          await createUserApi(requestBody)
          return { success: true, mode: 'online' }
        }
      }
    } catch (error) {
      const payload = {
        userData: requestBody,
        error: {
          status: error?.status,
          message: error?.statusText || error?.message || error
        }
      }

      try {
        if (error?.errorResponse?.message !== 'emailAlreadyExists') await failUserSignup(payload)
      } catch (failUserError) {
        console.log('failUserError', failUserError)
      }

      return { success: false, errorResponse: error.errorResponse }
    }
  } else if (payload.action === USER_ACTIONS.update) {
    // Update
    try {
      if (!navigator.onLine) throw new Error('No internet connection')

      await agentUpdateUser(requestBody)
      return { success: true }
    } catch (error) {
      const payload = {
        userData: requestBody,
        error: {
          status: error?.status,
          message: error?.statusText || error?.message || error
        }
      }

      try {
        await failUserUpdate(payload)
      } catch (failUserError) {
        console.log('failUserError', failUserError)
      }

      return { success: false }
    }
  }
}
