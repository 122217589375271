import React, { useEffect } from 'react'
import { Form, Formik } from 'formik'
import { useAccountCreation } from 'contexts/AccountCreation'
import { useLocalForage } from 'contexts/LocalForage'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Fieldset from 'components/FormComponents/Fieldset'
import FieldsetItem from 'components/FormComponents/Fieldset/FieldsetItem'
import ButtonCta from 'components/Button/ButtonCta'
import Checkbox from 'components/Checkbox'
import Separator from 'components/Separator'
import Referrer from 'components/AccountCreation/CreationFormThirdStep/Referrer'
import BundlesForm from 'components/AccountCreation/CreationFormThirdStep/BundlesForm'
import { getBundle } from 'components/AccountCreation/CreationFormThirdStep/BundlesForm/Bundle'
import { AGENT_CONSENT_TEXT, USER_TYPE } from 'utils/constants'
import { AGENT_APP_VERSION } from 'utils/version'
import { isUserObjValid } from 'utils/validators'
import { validationSchema, getInitialValues } from 'components/AccountCreation/CreationFormThirdStep/formValidation'
import PreferredBrand from './PreferredBrand'
import { createOrUpdateUser, USER_ACTIONS, SyncData } from 'api/users'
import toast from 'react-hot-toast'

const CreationFormThirdStep = () => {
  const { user, resetUser, resetStep, resetTimer, elapsedTime, previousStep, setFormError } = useAccountCreation()
  const { getStoredItem } = useLocalForage()

  const handleThirdStepSubmit = async (values, { resetForm }) => {
    // Filter bundles to keep only bundle with a device or flavours
    const bundlesGiven = values.bundlesGiven.map(bundle => getBundle(bundle)).filter(bundle => bundle)

    const appBuildId = process.env.BUILD_ID
    const appCommitRef = process.env.COMMIT_REF

    const newUser = {
      ...user,
      ...values,
      bundlesGiven,
      agentAppTracking: {
        elapsedTime,
        appBuildId,
        appCommitRef,
        appVersion: AGENT_APP_VERSION,
        userType: USER_TYPE.new
      }
    }

    if (!isUserObjValid(newUser)) {
      toast.error('An error occurred please try again.')
      resetStep()
      return
    }

    const agentJWT = await getStoredItem('agentJWT')
    const requestBody = { user: newUser, agentJWT, action: USER_ACTIONS.create }

    try {
      const response = await createOrUpdateUser(requestBody)
      if (response.success) {
        if (response.mode === 'online') {
          resetForm()
          resetUser()
          resetStep()
          resetTimer()
          toast.success('User has been created')
        } else {
          resetForm()
          resetUser()
          resetStep()
          resetTimer()
          toast.success('User has been created in Offline DB')
        }
      } else {
        let errorMessage = 'Error occurred while submitting user.'
        if (response?.errorResponse?.message !== '') {
          if (response?.errorResponse?.errorMessage !== '') errorMessage = response?.errorResponse?.errorMessage
          switch (response?.errorResponse?.message) {
            case 'emailAlreadyExists':
              resetForm()
              resetUser()
              resetStep()
              resetTimer()
              break
            case 'phoneNumberDobExists':
              errorMessage =
                'Phone Number & DOB combination Exists, Please ensure your information is correct before resubmitting'
              setFormError({ dob: true, phoneNumber: true })
              previousStep()

              break
            case 'phoneNumberExists':
              setFormError({ phoneNumber: true })
              previousStep()
              break
            default:
              resetForm()
              resetUser()
              resetStep()
              resetTimer()
              break
          }
        }

        if (!errorMessage) errorMessage = 'Error occurred while submitting user.'

        toast.error(errorMessage)
      }
      return response
    } catch (error) {
      console.log(error, 'user creation error')
      toast.error('An error occurred please try again.')
      resetStep()
      return error
    }
  }

  useEffect(() => {
    // Extra check to test if the user object is well formed
    if (!isUserObjValid(user)) {
      toast.error('An error occurred please try again.')
      resetStep()
    }
  }, [user])

  useEffect(() => {
    function checkOnlineStatus() {
      if (navigator.onLine) {
        toast.success('online')
        SyncData()
      } else {
        toast.success('Offline, will sync when online')
      }
    }
    // Listen for online event
    window.addEventListener('online', checkOnlineStatus)
    window.addEventListener('offline', checkOnlineStatus)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('online', checkOnlineStatus)
      window.removeEventListener('offline', checkOnlineStatus)
    }
  }, [])

  return (
    <Section title={'Help us better understand our consumers'}>
      <Wrapper>
        <Formik
          initialValues={getInitialValues(user)}
          validationSchema={validationSchema}
          onSubmit={handleThirdStepSubmit}
          validateOnChange
        >
          {({ values, handleChange, isSubmitting }) => {
            return (
              <Form>
                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <Checkbox
                      label="Consumer is of legal age"
                      titleLabel
                      name="agentConsent"
                      onChange={handleChange}
                      checked={values.agentConsent}
                    />
                  </FieldsetItem>

                  <FieldsetItem xs="1-1" noPaddingTop>
                    <p>{AGENT_CONSENT_TEXT}</p>
                  </FieldsetItem>
                </Fieldset>

                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <Referrer label="Was the consumer Invited by a friend?" name="userRefererId" optional={2} />
                  </FieldsetItem>
                </Fieldset>

                <BundlesForm name="bundlesGiven" />

                <PreferredBrand />

                <Separator />

                <Fieldset>
                  <FieldsetItem xs="1-1">
                    <ButtonCta type="submit" disabled={!values.agentConsent} isLoading={isSubmitting}>
                      Confirm and submit
                    </ButtonCta>
                  </FieldsetItem>
                </Fieldset>
              </Form>
            )
          }}
        </Formik>
      </Wrapper>
    </Section>
  )
}

export default CreationFormThirdStep
