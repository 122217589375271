import React, { useState, useContext } from 'react'
import { bool, number, oneOfType, string } from 'prop-types'
import styled from 'styled-components'
import { SessionContext } from 'contexts/Session'
import { useFormikContext } from 'formik'
import { getReferrers } from 'api/agents'
import AutoComplete from 'components/FormComponents/AutoComplete'
import Input from 'components/FormComponents/Input/Input'

const ContainerAutocomplete = styled.div`
  display: block;
  width: 100%;
  border-radius: 0.125rem;
  padding: 1em;
  margin-top: 1em;
  color: black;
  background-color: ${props => (props.rewards === 'true' ? '#ccf2ff' : '#ffff99')};

  p {
    margin-bottom: 0;
  }
`

const Referrer = ({ name, label, optional, referrerUserEmail }) => {
  const { isOnline } = useContext(SessionContext)
  const { values, setFieldValue } = useFormikContext()
  const [referrers, setReferrers] = useState([])
  const [referer, setReferer] = useState(null)

  const filterReferrerSuggestions = async term => {
    if (typeof term !== 'undefined' && term.length >= 5) {
      const result = await getReferrers({ term })

      const suggestions = []
      const referrersInformation = []
      result.referrers.forEach(referrerInfo => {
        const { firstName, lastName, email } = referrerInfo
        referrersInformation[email] = referrerInfo
        suggestions.push({ value: email, label: `${firstName} ${lastName} - ${email}` })
      })

      setReferrers({ options: suggestions, referrersInformation })

      return suggestions
    }

    return null
  }

  const onChange = e => {
    setReferer(null)
    setFieldValue(name, '')
  }

  const onSelect = referrerSelected => {
    const referrer = referrers.referrersInformation[referrerSelected.value]
    setReferer(referrer)
    setFieldValue(name, referrer.id)
  }

  return (
    <>
      {referrerUserEmail ? (
        <Input label={label} optional={optional} disabled value={referrerUserEmail} />
      ) : (
        <>
          {isOnline ? (
            <AutoComplete
              filterSuggestions={filterReferrerSuggestions}
              onChange={onChange}
              onSelect={onSelect}
              label={label}
              optional={optional}
              placeholder="Search user by name or email address"
            />
          ) : (
            <Input
              label={label}
              placeholder="Search user by name or email address"
              optional={optional}
              disabled
              /** meta={{
                error: 'Internet connection problem, you cannot search for a user',
                touched: true
              }} */
            />
          )}

          {values.userRefererId && referer && (
            <ContainerAutocomplete rewards={referer?.rewards}>
              <h3>Referrer Information</h3>
              <p>{`${referer.firstName} ${referer.lastName}`}</p>
              <p>{`#${referer.id}`}</p>
              <p>{`${referer.email}`}</p>
              {referer?.rewards === 'false' && (
                <div>
                  <br />
                  <p>The referrer is not registered within the Vuse Rewards loyalty program!</p>
                </div>
              )}
            </ContainerAutocomplete>
          )}
        </>
      )}
    </>
  )
}

Referrer.propTypes = {
  name: string.isRequired,
  label: string,
  optional: oneOfType([bool, number]),
  referrerUserEmail: string
}

export default Referrer
